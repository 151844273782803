
import { Component, Vue } from "vue-property-decorator";
import moment from "moment-timezone";

@Component({
  components: {
    Task: () => import("@/app/modules/tasks/components/TaskComponent.vue")
  }
})
export default class TasksPage extends Vue {
  statuses = [
    { id: 0, status: this.$t("EREPORTER.FILTER.ALL") },
    { id: 1, status: this.$t("EREPORTER.FILTER.NEW") },
    { id: 2, status: this.$t("EREPORTER.FILTER.ACCEPTED") },
    { id: 3, status: this.$t("EREPORTER.FILTER.FINISHED") },
    { id: 6, status: this.$t("EREPORTER.FILTER.LATE_CANCELLATION") }
  ];

  periods = [
    {
      id: 0,
      period: this.$t("EREPORTER.FILTER.CURRENT_WEEK"),
      start: moment()
        .add(0, "week")
        .startOf("isoWeek"),
      end: moment()
        .add(0, "week")
        .endOf("isoWeek")
    },
    {
      id: 1,
      period: this.$t("EREPORTER.FILTER.NEXT_WEEK"),
      start: moment()
        .add(1, "week")
        .startOf("isoWeek"),
      end: moment()
        .add(1, "week")
        .endOf("isoWeek")
    },
    {
      id: 2,
      period: this.$t("EREPORTER.FILTER.PREVIOUS_WEEK"),
      start: moment()
        .subtract(1, "week")
        .startOf("isoWeek"),
      end: moment()
        .subtract(1, "week")
        .endOf("isoWeek")
    },
    {
      id: 3,
      period: this.$t("EREPORTER.FILTER.CURRENT_MONTH"),
      start: moment()
        .add(0, "month")
        .startOf("month"),
      end: moment()
        .add(0, "month")
        .endOf("month")
    },
    {
      id: 4,
      period: this.$t("EREPORTER.FILTER.NEXT_MONTH"),
      start: moment()
        .add(1, "month")
        .startOf("month"),
      end: moment()
        .add(1, "month")
        .endOf("month")
    },
    {
      id: 5,
      period: this.$t("EREPORTER.FILTER.PREVIOUS_MONTH"),
      start: moment()
        .subtract(1, "month")
        .startOf("month"),
      end: moment()
        .subtract(1, "month")
        .endOf("month")
    }
  ];

  mounted() {
    moment.tz.setDefault();
    this.fetchTasks();
  }

  get deviceInfo() {
    return this.$store.getters["device/deviceInfo"];
  }

  get statusId() {
    return this.$store.getters["tasks/status"];
  }

  set statusId(value) {
    this.$store.commit("tasks/setStatus", value);
  }

  get periodId() {
    return this.$store.getters["tasks/period"];
  }

  set periodId(value) {
    this.$store.commit("tasks/setPeriod", value);
  }

  get profileData() {
    return this.$store.getters["profile/data"];
  }

  get tasksData() {
    return this.$store.getters["tasks/data"];
  }

  get tasksDataStatusFiltered() {
    return this.statusId == 0
      ? this.tasksData
      : this.tasksData?.filter(task => task.status_id == this.statusId);
  }

  get tasksDataPeriodFiltered() {
    const unsorted = this.tasksDataStatusFiltered?.filter(task => {
      const period = this.periods[this.periodId];
      return moment(task.date_from).isBetween(
        period.start,
        period.end,
        undefined,
        "[]"
      );
    });

    return unsorted
      ?.slice()
      .sort((a, b) => moment(b.date_from).unix() - moment(a.date_from).unix());
  }

  fetchTasks() {
    this.$store.dispatch("tasks/fetchAll").then(({ data }) => {
      this.$store.commit("tasks/setData", data.data);
    });
  }
}
